<template lang="pug">
.empty-data
  el-empty
    template(v-slot:description) {{ description === 'error' ? '' : text }}
    template(v-if="description !== 'none'" v-slot:default)
      .description
        span.no-data__error(v-if="description === 'error'") При загрузке данных произошла ошибка
        span.no-data__description(v-else-if="description === 'filters'") Попробуйте использовать другие фильтры
        ui-button.action(v-if="showReloadButton" type="secondary" @click="$emit('reload')") Повторить
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import UiButton from "~/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "NoData",
  components: {
    UiButton,
  },
  props: {
    text: {
      type: String,
      default: 'Нет данных',
    },
    description: {
      type: String as PropType<'none'|'error'|'filters'>,
      default: 'none',
    },
    showReloadButton: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.empty-data {
  @include label-13-16;

  display: flex;
  justify-content: center;

  height: 320px;

  .no-data__description {
    font-size: 12px;
    color: var(--main-placeholder-color);
  }

  .no-data__error {
    font-size: 12px;
    color: var(--main-red-color);
  }

  .description {
    display: flex;
    flex-flow: column;
    gap: 16px;
    align-items: center;

    .action {
      width: 200px;
      justify-content: center;
    }
  }
}
</style>
